<template>
  <div class="bulk-overview">
    <ElementButton
      type="primary"
      plain
      size="small"
      icon="el-icon-arrow-right"
      class="bulk-overview__creation-button"
      @click="goToStyles"
    >
      Go to Styles
    </ElementButton>
    <div
      v-if="stylesData.length > 0"
      class="bulk-overview__success-message"
    >
      <ElementAlert
        :title="messageToDisplay"
        type="success"
        show-icon
      />
    </div>
    <ElementTable
      :data="stylesData"
      border
      stripe
      class="bulk-overview__table"
      :cell-style="{ height: '55px' }"
      empty-text="Styles not found."
    >
      <ElementTableColumn
        label="PLM Code"
        prop="plmCode"
      />

      <ElementTableColumn
        label="Style Name"
        prop="name"
      />

      <ElementTableColumn
        label="Season"
        prop="season"
      />
    </ElementTable>
  </div>
</template>

<script>
import VueTypes from 'vue-types'

export default {
  name: 'StyleBulkOverview',

  props: {
    styles: VueTypes.array,
    seasons: VueTypes.array
  },

  data () {
    return {
      isLoading: false,
      stylesData: [],
      messageToDisplay: ''
    }
  },

  watch: {
    /**
     *
     */
    styles () {
      this.initStylesData()
    }
  },

  methods: {
    /**
     */
    goToStyles () {
      this.$router.push({
        path: '/styles/',
        query: { group_id: this.$route.query.group_id }
      })
    },

    /**
     */
    initStylesData () {
      this.stylesData = this.styles.map(style => {
        return {
          id: style.id,
          name: style.name,
          plmCode: style.plm_code,
          season: this.seasons.find(season => season.id === style.season_id).name
        }
      })

      this.messageToDisplay = this.stylesData.length === 1
        ? '1 style has been successfully created.'
        : `${this.stylesData.length} styles has been successfully created.`
    }
  }
}
</script>

<style lang="scss" scoped>
.bulk-overview {
  margin: spacing(4) 0 spacing(2) 0;
}

.bulk-overview__table {
  margin-bottom: spacing(2);
}

.bulk-overview__creation-button {
  position: absolute;
  top: spacing(4);
  right: spacing(4.2);
}

.bulk-overview__success-message {
  margin-bottom: spacing(2);
}
</style>
