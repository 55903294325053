<template>
  <div>
    <ElementForm
      ref="creationForm"
      :model="creationForm"
      :rules="validationRules"
      label-position="top"
      class="bulk-creation"
    >
      <ElementFormItem
        label="PLM Code(s)"
        prop="codes"
      >
        <div class="bulk-creation-code-editor el-input__inner">
          <div class="bulk-creation-code-editor__codes">
            <ElementTag
              v-for="(item, itemIndex) in creationForm.codes"
              :key="itemIndex"
              closable
              size="mini"
              :type="item.isValid ? '' : 'danger'"
              class="bulk-creation-code-editor__code"
              @close="removeCode(item)"
            >
              {{ item.code }}
            </ElementTag>
            <input
              ref="editorCodeField"
              v-model.trim="editorCode"
              class="bulk-creation-code-editor__input"
              @keydown.backspace.stop="removeLastCode"
              @keydown.enter.prevent.stop="tokenizeNewCode"
              @keydown.space.prevent.stop="tokenizeNewCode"
              @keydown.tab.prevent.stop="tokenizeNewCode"
              @keydown.comma.prevent.stop="tokenizeNewCode"
              @paste.prevent.stop="handlePaste"
            >
          </div>
        </div>
      </ElementFormItem>
      <ElementFormItem
        label="Season"
        prop="seasonName"
      >
        <ElementSelect
          v-model="creationForm.seasonName"
          placeholder="Choose a Season"
          filterable
          clearable
        >
          <ElementOption
            v-for="season in seasons"
            :key="season.id"
            :label="season.name"
            :value="season.name"
          />
        </ElementSelect>
      </ElementFormItem>
      <ElementFormItem>
        <ElementButton
          type="primary"
          :loading="isLoading"
          icon="el-icon-check"
          plain
          @click="submitForm('creationForm')"
        >
          Verify
        </ElementButton>
        <ElementButton
          icon="el-icon-refresh-left"
          @click="resetForm('creationForm')"
        >
          Reset
        </ElementButton>
        <ElementButton
          type="danger"
          icon="el-icon-close"
          plain
          @click="backToHome"
        >
          Cancel
        </ElementButton>
      </ElementFormItem>
    </ElementForm>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueTypes from 'vue-types'

const REGEX_PLM = /^[a-zA-Z0-9]{10}$/

export default {
  name: 'StyleBulkCreation',

  props: {
    seasons: VueTypes.array
  },

  data () {
    return {
      isLoading: false,
      creationForm: {
        codes: [],
        seasonName: null
      },
      editorCode: '',
      validationRules: {
        codes: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value.length > 0) {
                callback()
              } else {
                callback(new Error('Please provide at least one PLM code'))
              }
            },
            trigger: ['blur', 'change']
          },
          {
            validator: this.validatorPLMList
          }
        ],
        seasonName: [
          {
            required: true,
            message: 'Please choose a season',
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },

  computed: {
    ...mapGetters({
      user: 'getCognitoUserData'
    })
  },

  methods: {
    ...mapActions(['validatePlmCodes']),

    /**
     *
     */
    async submitForm () {
      this.tokenizeNewCode()
      await this.$refs.creationForm.validate(async isValid => {
        if (!isValid) {
          return
        }

        this.isLoading = true

        const codes = await this.validatePlmCodes({
          codes: this.creationForm.codes.map(item => item.code),
          seasonName: this.creationForm.seasonName
        })

        this.isLoading = false

        this.$emit('set-bulk-creation-data', {
          codes: codes,
          seasonName: this.creationForm.seasonName
        })
      })
    },

    /**
     */
    resetForm () {
      this.$refs.creationForm.resetFields()
    },

    /**
     */
    backToHome () {
      this.$emit('back-to-homepage')
    },

    /**
     * @param {object}   rule
     * @param {Array}    value
     * @param {Function} callback
     */
    validatorPLMList (rule, value, callback) {
      const uniqueCodes = {}

      value.forEach(item => {
        const code = item.code.toLowerCase()

        if (new RegExp(REGEX_PLM).test(code)) {
          item.isValid = true
        } else {
          item.isValid = false
        }

        if (uniqueCodes[code]) {
          item.isValid = false
        } else {
          uniqueCodes[code] = true
        }
      })

      if (value.every(item => item.isValid)) {
        callback()
      } else {
        callback(
          new Error(
            'Please provide valid PLM codes. No duplicates are allowed.'
          )
        )
      }
    },

    /**
     *
     */
    tokenizeNewCode () {
      const pendingInput = this.editorCode

      if (pendingInput !== '') {
        this.creationForm.codes.push({
          code: pendingInput,
          isValid: true
        })
        this.editorCode = ''
      }

      this.validatePLMListField()
      this.focusCodeField()
    },

    /**
     */
    validatePLMListField () {
      this.$refs.creationForm.validateField('codes')
    },

    /**
     */
    focusCodeField () {
      this.$nextTick().then(() => {
        this.$refs.editorCodeField.focus()
      })
    },

    /**
     * @param {ClipboardEvent} pasteEvent
     */
    handlePaste (pasteEvent) {
      const newCodes = this.splitPasteContent(
        pasteEvent.clipboardData.getData('Text')
      ).map(code => ({ code, isValid: true }))

      this.creationForm.codes.push(...newCodes)
      this.validatePLMListField()
      this.focusCodeField()
    },

    /**
     * @param   {string}   pasteContent
     *
     * @returns {string[]}
     */
    splitPasteContent (pasteContent) {
      let parts

      if (!pasteContent) {
        return []
      }

      const pasteContentTrimmed = pasteContent.trim()
      const regexes = [
        /,\s*/g, // comma with optional spaces after
        /\s+/g // spaces
      ]

      for (const regex of regexes) {
        const trimParts = pasteContentTrimmed
          .split(regex)
          .filter(part => part.length > 0)
        parts = trimParts.map(code => code.trim())

        if (parts.length > 0 && parts[0] !== pasteContentTrimmed) {
          return parts
        }
      }

      return parts
    },

    /**
     * @param {Event} event
     */
    removeLastCode (event) {
      if (event.target.value === '') {
        event.preventDefault()

        const codes = this.creationForm.codes

        if (codes.length > 0) {
          this.removeCode(codes[codes.length - 1])
        }
      }
    },

    /**
     * @param {object} item
     */
    removeCode (item) {
      const index = this.creationForm.codes.findIndex(
        codeFromList => codeFromList.code === item.code
      )

      if (index > -1) {
        this.creationForm.codes.splice(index, 1)
      }

      this.validatePLMListField()
      this.focusCodeField()
    }
  }
}
</script>

<style lang="scss" scoped>
$max-height-editor: 300px;
$gap-codes: spacing(1/2);
$top-code-tag-close: 1px;
$padding-code-add-button: 9px 6px;

.bulk-creation {
  margin-top: spacing(4);
}

.bulk-creation-code-editor {
  display: flex;
  justify-content: space-between;
  width: percentage(35);
  height: auto;
  min-height: spacing(10);
  max-height: $max-height-editor;
  padding: $gap-codes;
}

.bulk-creation-code-editor__codes {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  overflow: auto;
  border: $gap-codes solid transparent;
}

.bulk-creation-code-editor__code {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  margin: 0 spacing(1) spacing(1) 0;

  /deep/ .el-tag__close {
    top: $top-code-tag-close;
  }
}

.bulk-creation-code-editor__input {
  display: flex;
  flex-shrink: 1;
  width: 100%;
  padding: spacing(1/2) 0;
  font-size: inherit;
}
</style>
