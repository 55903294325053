<template>
  <div>
    <ElementTabs
      :value="activeTab"
      type="border-card"
      @tab-click="setActiveTab"
    >
      <ElementTabPane
        :label="STEPS.CREATION.label"
        :name="STEPS.CREATION.name"
        :disabled="activeTab === STEPS.OVERVIEW.name"
      >
        <ElementCard
          shadow="none"
          class="style-actions__card"
        >
          <h3>Enter PLM code(s) and Season</h3>
          <StyleBulkCreation
            :seasons="seasons"
            @set-bulk-creation-data="setBulkCreationData"
            @back-to-homepage="backToHomepage"
          />
        </ElementCard>
      </ElementTabPane>
      <ElementTabPane
        :label="STEPS.VALIDATION.label"
        :name="STEPS.VALIDATION.name"
        :disabled="activeTab !== STEPS.VALIDATION.name"
      >
        <ElementCard
          shadow="none"
          class="style-actions__card"
        >
          <h3>Choose Status and Vendor Company</h3>
          <StyleBulkValidation
            :valid-codes="plmCodes.valid"
            :invalid-codes="plmCodes.invalid"
            :duplicated-codes="plmCodes.duplicates"
            :season="season"
            :vendors="vendors"
            @set-created-styles="setCreatedStyles"
            @back-to-previous-step="backToPreviousStep"
            @back-to-homepage="backToHomepage"
          />
        </ElementCard>
      </ElementTabPane>
      <ElementTabPane
        :label="STEPS.OVERVIEW.label"
        :name="STEPS.OVERVIEW.name"
        :disabled="activeTab !== STEPS.OVERVIEW.name"
      >
        <ElementCard
          shadow="none"
          class="style-actions__card"
        >
          <h3>List of Created Styles</h3>
          <StyleBulkOverview
            :styles="newlyCreatedStyles"
            :seasons="seasons"
          />
        </ElementCard>
      </ElementTabPane>
    </ElementTabs>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import StyleBulkCreation from '@/components/style/StyleActions/components/StyleBulkCreation.vue'
import StyleBulkValidation from '@/components/style/StyleActions/components/StyleBulkValidation.vue'
import StyleBulkOverview from '@/components/style/StyleActions/components/StyleBulkOverview.vue'

const STEPS = {
  CREATION: {
    name: 'creation',
    label: '1. PLM Code(s) and Season'
  },
  VALIDATION: {
    name: 'validation',
    label: '2. Validation'
  },
  OVERVIEW: {
    name: 'overview',
    label: '3. Overview'
  }
}

export default {
  name: 'StyleActions',

  components: { StyleBulkCreation, StyleBulkValidation, StyleBulkOverview },

  data () {
    return {
      STEPS,
      isLoading: false,
      plmCodes: {
        valid: [],
        invalid: [],
        duplicates: []
      },
      season: null,
      activeTab: STEPS.CREATION.name,
      newlyCreatedStyles: null
    }
  },

  computed: {
    ...mapGetters({
      settings: 'getNotificationsSettings',
      user: 'getCognitoUserData',
      seasons: 'getSeasons',
      vendors: 'getVendors'
    })
  },

  watch: {
    /**
     * @param {string} to
     * @param {string} from
     */
    async $route (to, from) {
      await this.fetchAssignableVendorsByTeam({
        teamId: this.$route.query.group_id
      })
    }
  },

  async created () {
    await this.fetchSeasons()
    await this.fetchAssignableVendorsByTeam({
      teamId: this.$route.query.group_id
    })
  },

  methods: {
    ...mapActions([
      'fetchNotificationsSettings',
      'updateNotificationsSettings',
      'fetchAssignableVendorsByTeam',
      'fetchSeasons'
    ]),

    /**
     * @param {object} value
     */
    setBulkCreationData (value) {
      const { codes, seasonName } = value
      this.plmCodes.valid = codes.valid_codes
      this.plmCodes.invalid = codes.invalid_codes
      this.plmCodes.duplicates = codes.duplicate_codes
      this.season = this.seasons.find(s => s.name === seasonName)
      this.activeTab = STEPS.VALIDATION.name
    },

    /**
     * @param {object} value
     */
    setCreatedStyles (value) {
      this.newlyCreatedStyles = value.styles
      this.activeTab = STEPS.OVERVIEW.name
    },

    /**
     */
    backToPreviousStep () {
      this.activeTab = STEPS.CREATION.name
    },

    /**
     */
    backToHomepage () {
      this.$router.push({
        name: 'LibraryStylesTypes',
        query: { group_id: this.$route.query.group_id }
      })
    },

    /**
     * @param {object} tab
     */
    setActiveTab (tab) {
      this.activeTab = tab.name
    }
  }
}
</script>

<style lang="scss" scoped>
$notifications-group-margin: 16px 0 0 20px;

table {
  width: 100%;
  font-family: arial, sans-serif;
  border-collapse: collapse;
}

td,
th {
  padding: spacing(1);
  text-align: left;
}

.style-actions__card {
  margin-bottom: spacing(1);
}

.style-actions__card h3 {
  font-weight: $font-weight-medium;
  font-size: spacing(2.5);
}
</style>
