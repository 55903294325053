<template>
  <div class="bulk-validation">
    <div
      class="bulk-validation__invalid-codes"
    >
      <ElementAlert
        v-if="invalidCodes.length > 0"
        title="Unable to create styles with PLM codes:"
        type="error"
        :description="invalidCodes.join(', ')"
        show-icon
      />

      <ElementAlert
        v-if="duplicatedCodes.length > 0"
        :title="`The following PLM codes already exist in Season ${season.name}:`"
        type="warning"
        :description="duplicatedCodes.join(', ')"
        show-icon
      />
    </div>
    <ElementTable
      :data="displayPLMData"
      border
      stripe
      class="bulk-validation__table"
      :cell-style="{ height: '55px' }"
      empty-text="Data not found."
    >
      <ElementTableColumn
        label="PLM Code"
        prop="plmCode"
      />

      <ElementTableColumn
        label="Season"
        prop="season"
      >
        <template slot-scope="scope">
          {{ scope.row.season.name }}
        </template>
      </ElementTableColumn>

      <ElementTableColumn
        label="Vendor Company"
        prop="vendorCompany"
      >
        <template slot-scope="scope">
          <ElementSelect
            v-model="scope.row.vendorCompany"
            size="mini"
          >
            <ElementOption
              v-for="vendor in vendors"
              :key="`${vendor.name}`"
              :label="vendor.name"
              :value="vendor.id"
            />
          </ElementSelect>
        </template>
      </ElementTableColumn>

      <ElementTableColumn
        label="Status"
        prop="status"
      >
        <template slot="header">
          <div class="bulk-validation__status-checkbox">
            Status
            <ElementCheckbox
              @change="setStatus"
            >
              Set all to Requested
            </ElementCheckbox>
          </div>
        </template>
        <template slot-scope="scope">
          <ElementSelect
            v-model="scope.row.status"
            size="mini"
          >
            <ElementOption
              v-for="status in [ASSET_STATUS.CREATED, ASSET_STATUS.REQUESTED]"
              :key="`${status.action}`"
              :label="status.action"
              :value="status.value"
            />
          </ElementSelect>
        </template>
      </ElementTableColumn>
    </ElementTable>

    <ElementPagination
      v-if="plmData.length > 0"
      align="right"
      layout="prev, pager, next"
      :total="plmData.length"
      :page-size="pageSize"
      :current-page="currentPage"
      @current-change="setPage"
    />

    <ElementButton
      type="primary"
      class="bulk-validation__back-button"
      icon="el-icon-arrow-left"
      plain
      @click="backToCreation"
    >
      Previous Step
    </ElementButton>
    <ElementButton
      type="danger"
      class="bulk-validation__back-button"
      icon="el-icon-close"
      plain
      @click="backToHome"
    >
      Cancel
    </ElementButton>
    <ElementButton
      type="primary"
      class="bulk-validation__creation-button"
      :loading="isLoading"
      :disabled="plmData.length === 0"
      @click="createStyles"
    >
      Create Styles
    </ElementButton>
  </div>
</template>

<script>
import VueTypes from 'vue-types'
import { ASSET_STATUS } from '@/constants/assetStatuses'
import { mapActions } from 'vuex'

export default {
  name: 'StyleBulkValidation',

  props: {
    validCodes: VueTypes.array,
    invalidCodes: VueTypes.array,
    duplicatedCodes: VueTypes.array,
    season: VueTypes.object,
    vendors: VueTypes.array
  },

  data () {
    return {
      isLoading: false,
      ASSET_STATUS,
      plmData: [],
      currentPage: 1,
      pageSize: 10
    }
  },

  computed: {
    /**
     * @returns { Array }
     */
    displayPLMData () {
      const start = (this.currentPage - 1) * this.pageSize
      const end = start + this.pageSize

      return this.plmData.slice(start, end)
    }
  },

  watch: {
    /**
     *
     */
    validCodes () {
      this.initPlmData()
    }
  },

  methods: {
    ...mapActions(['stylesBulkCreation']),

    /**
     * @param {number} page
     */
    setPage (page) {
      this.currentPage = page
    },

    /**
     * @param {boolean} value
     */
    setStatus (value) {
      const status = value
        ? ASSET_STATUS.REQUESTED.value
        : ASSET_STATUS.CREATED.value

      this.plmData = this.plmData.map(code => {
        return {
          ...code,
          status: status
        }
      })
    },

    /**
     */
    async createStyles () {
      this.isLoading = true

      const styles = await this.stylesBulkCreation({
        groupId: this.$route.query.group_id,
        data: this.processPlmData(this.plmData)
      })

      this.isLoading = false

      this.$emit('set-created-styles', {
        styles
      })
    },

    /**
     */
    backToCreation () {
      this.$emit('back-to-previous-step')
    },

    /**
     */
    backToHome () {
      this.$emit('back-to-homepage')
    },

    /**
     */
    initPlmData () {
      this.plmData = this.validCodes.map(code => {
        return {
          plmCode: code,
          status: ASSET_STATUS.CREATED.value,
          vendorCompany: null,
          season: this.season
        }
      })
    },

    /**
     * @param   {Array}    data
     *
     * @returns {object[]}
     */
    processPlmData (data) {
      return data.map(item => {
        return {
          plm_code: item.plmCode,
          season_id: item.season.id,
          status: item.status,
          vendor_company_id: item.vendorCompany
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.bulk-validation {
  margin: spacing(2) 0 spacing(2) 0;
}

.bulk-validation__table {
  margin-bottom: spacing(2);
}

.bulk-validation__creation-button {
  float: right;
  margin-bottom: spacing(2);
}

.bulk-validation__back-button {
  float: left;
  margin-bottom: spacing(2);
}

.bulk-validation__invalid-codes {
  margin-bottom: spacing(2);
}

.bulk-validation__invalid-codes p {
  color: $red;
  font-size: spacing(1.5);
}

.bulk-validation__invalid-codes .el-alert {
  margin-bottom: spacing(0.5);
}
</style>

<style lang="scss">
.bulk-validation__status-checkbox .el-checkbox {
  float: inline-end;
}

.bulk-validation__status-checkbox .el-checkbox__label {
  padding-left: spacing(1);
  font-size: $font-size-label;
}
</style>
